import { forwardRef } from 'react';
import './Form.css';
import { FormInput } from './FormInput';

export const Form = forwardRef(({ fields, initialData = {} }, ref) => {
  return (
    <form className="Form" ref={ref}>
      {Object.keys(fields).map((id) => {
        const prefilled = initialData[id];
        const { label, type } = fields[id];
        return (
          <div className="FormRow" key={id}>
            <label className="label" htmlFor={id}>
              
            </label>
            {label} <FormInput
              id={id}
              type={type}
              defaultValue={prefilled}
            />
          </div>
        );
      })}
    </form>
  );
});