import React from 'react';

import { useGanttContext } from '../../contexts/GanttContext';

export const GanttMode = () => {
    const {setMode, mode} = useGanttContext();

    const options = ["Month", "Week", "Day", "Half Day", "Quarter Day"];

    const handleChange = (event) => {
        setMode(event.target.value);
    };

    return (<div className='gantt-mode select'>
        <select value={mode} onChange={handleChange}>
            {options.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>
    </div>
    );
};