import { FrappeGantt } from 'frappe-gantt-react';
import "./Gantt.css";
import { useGanttContext } from '../../contexts/GanttContext';

export const Gantt = () => {
    const { tasks, setTasks, setClickedTask, mode } = useGanttContext();

    const changeDate = (task, start, end) => {
        const foundTask = tasks.find(item => item.id === task.id);
        foundTask.start = start.toISOString().split('T')[0]
        foundTask.end = end.toISOString().split('T')[0]
        setTasks(tasks)
    }

    const changeProgress = (task, progress) => {
        const foundTask = tasks.find(item => item.id === task.id);
        foundTask.progress = progress
        setTasks(tasks)
    }

    const onClick = (task) => {
        setClickedTask(task);
    }

    return tasks.length ? <FrappeGantt
        tasks={tasks}
        viewMode={mode}
        onClick={onClick}
        onDateChange={changeDate}
        onProgressChange={changeProgress}
    /> : null
}
