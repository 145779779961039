import { useRef, useState } from 'react';
import './Header.css';

import { schema, importSchema } from '../../config/schema';
import { Dialog } from '../dialog/Dialog';
import { Form } from '../form/Form';
import { useGanttContext } from '../../contexts/GanttContext';

import { GanttMode } from '../gantt/GanttMode';

import { clone, getJsonForGantt, commitToStorage } from '../../helpers/GanttHelper';

export const Header = () => {
  const {
    tasks, mode, setTasks, clickedTask
  } = useGanttContext();
  const [isAddOrUpdate, setAddOrUpdate] = useState();
  const [isImportOrExport, setImport] = useState();

  const form = useRef(null);

  const addOrUpdate = (action) => {
    setAddOrUpdate(false);
    if (action === 'dismiss') {
      return;
    }

    const formData = {};
    Array.from(form.current).forEach(
      (input) => (formData[input.id] = input.value),
    );

    let finalTasks;
    const foundItem = tasks.find(item => item.id === formData.id);
    if (foundItem !== undefined) {
      const index = tasks.indexOf(foundItem);
      finalTasks = clone(tasks);
      finalTasks[index] = formData;
    } else {
      finalTasks = tasks.filter(item => item.id !== formData.id);
      finalTasks.unshift(formData);
    }
    setTasks(finalTasks);
  }

  const importGantt = (action) => {
    setImport(false);
    if (action === 'dismiss') {
      return;
    }
    const formData = {};
    Array.from(form.current).forEach(
      (input) => (formData[input.id] = input.value),
    );
    setTasks(JSON.parse(formData.json));
  }

  const move = (step, taskId) => {
    const foundItem = tasks.find(item => item.id === taskId);
    if (foundItem !== undefined) {
      const index = tasks.indexOf(foundItem);
      const finalTasks = tasks.filter(item => item.id !== taskId);
      finalTasks.splice(index + step, 0, clone(foundItem));
      setTasks(finalTasks);
    }
  }

  const clickedTaskId = clickedTask ? clickedTask.id : null;
  return (
    <>
      <div className='navbar-menu'>

        <div className="navbar-start">
          <GanttMode />
          {clickedTaskId ?
            <div className="header-item">
              <button className='button is-warning' onClick={() => move(-1, clickedTaskId)}>&#8593;</button>
              <button className='button is-warning' onClick={() => move(1, clickedTaskId)}>&#8595;</button>
            </div>
            : null}
        </div>
        <div className="navbar-start">
          <div className="header-item">
            <button className="button is-success" onClick={() => {
              setAddOrUpdate(true);
            }}>Добавить {clickedTaskId ? "или изменить " : ""}</button>
          </div>
        </div>

        <div className="navbar-start">
          <div className="header-item">
            <button className='button is-info' onClick={() => {
              let newData = clone(tasks);
              commitToStorage(newData, mode);
              setTasks(newData);
            }}>Сохранить</button>
          </div>
        </div>

        <div className="navbar-start">
          {clickedTaskId ?
            <div className="header-item">
              <button className='button is-danger' onClick={() => {
                const finalTasks = tasks.filter(item => item.id !== clickedTask.id);
                setTasks(finalTasks);
              }}>Удалить {clickedTaskId}</button>
            </div>
            : null}
        </div>

        <div className="navbar-end">
          <div className="header-item">
            <button className='button is-info' onClick={() => {
              setImport(true);
            }}>Импорт/Экспорт</button>
          </div>
        </div>
      </div>
      {isAddOrUpdate ? (
        <Dialog
          modal={true}
          header="Добавить или изменить"
          confirmLabel="Сохранить"
          onAction={(action) => addOrUpdate(action)}>
          <Form ref={form} fields={schema} initialData={clickedTask} />
        </Dialog>
      ) : null}
      {isImportOrExport ? (
        <Dialog
          modal={true}
          header="Импорт и экспорт"
          confirmLabel="Импорт"
          onAction={(action) => importGantt(action)}>
          <Form ref={form} fields={importSchema} initialData={getJsonForGantt(tasks)}></Form>
        </Dialog>
      ) : null}
    </>
  );
};