import { useState } from 'react';

export const FormInput = ({ type = 'input', defaultValue = '', id = '' }) => {
  const [customClass, setCustomClass] = useState(defaultValue);
  const customClasses = ["bar-gray", "bar-gold", "bar-green", "bar-red", "bar-brown"];
  switch (type) {
    case 'textarea':
      return <textarea className="textarea" defaultValue={defaultValue} resize="vertical" id={id} />;
    case 'date':
      return <input className="input" type="date" defaultValue={defaultValue} id={id}  />
    case 'range':
      return <input className="range" type="range" min="0" max="100" defaultValue={defaultValue} id={id} ></input>
    case 'ganttclass':
      return <select className="select" defaultValue={customClass} onChange={(event) => setCustomClass(event.target.value)} id={id}>
        {customClasses.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    default:
      return <input className="input" defaultValue={defaultValue} type="text" id={id} />;
  }
};