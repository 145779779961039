import { compress, decompress } from 'compress-json';

export const commitToStorage = (tasks, mode) => {
    const json = JSON.stringify(tasks);
    localStorage.setItem('data', json);
    localStorage.setItem('mode', mode);
    const compressed = compress({
        m: mode,
        t: tasks
    })
    const compressedString = JSON.stringify(compressed);
    const encoder = new TextEncoder();
    const data = encoder.encode(compressedString);
    const encoded = btoa(String.fromCharCode.apply(null, data));
    const urlParams = new URLSearchParams();
    urlParams.set('k', encoded);
    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
    window.history.pushState(null, '', newUrl);
}

export const clone = (o) => {
    return JSON.parse(JSON.stringify(o));
}

export const getJsonForGantt = (tasks) => {
    return {
        json: JSON.stringify(tasks)
    };
}

export const readCompressFromUrl = (key = 'z') => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(key);
    if (value == null) {
        return null
    }
    return decompressValue(value)
}


export const decompressValue = (value) => {
    const decoded = atob(value);
    const decoder = new TextDecoder();
    const data = decoder.decode(new Uint8Array([...decoded].map(char => char.charCodeAt(0))));
    return decompress(JSON.parse(data))
}

export const readGantt = () => {

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('d') != null) { // old base64 format
        return {
            m: localStorage.getItem('mode') || "Month",
            t: readFromUrl('d') || JSON.parse(localStorage.getItem('data')) || []
        }
    }
    if (urlParams.get('z') != null) { // old compress format
        return {
            m: localStorage.getItem('mode') || "Month",
            t: readCompressFromUrl('z') || JSON.parse(localStorage.getItem('data')) || []
        }
    }

    const kValue = urlParams.get('k')
    if (kValue != null) {
        return decompressValue(kValue);
    }

    return {
        m: localStorage.getItem('mode') || "Month",
        t: JSON.parse(localStorage.getItem('data')) || []
    }
}

export const readFromUrl = (key = 'd') => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(key);
    if (value == null) {
        return null
    }
    const decoded = atob(value);
    const decoder = new TextDecoder();
    const data = decoder.decode(new Uint8Array([...decoded].map(char => char.charCodeAt(0))));
    return [null, JSON.parse(data)];
}