import { useGanttContext } from '../../contexts/GanttContext';

export const Footer = () => {
    const { clickedTask } = useGanttContext();

    return (
        <footer className="footer">
            {clickedTask ?
                <div className="has-text-centered">
                    <strong>{clickedTask.id}</strong> {clickedTask.name} <strong>{clickedTask.start} - {clickedTask.end}</strong> {clickedTask.progress}% <a rel="noreferrer" target="_blank" href={clickedTask.link}>{clickedTask.link}</a>
                </div> :
                null
            }
        </footer>
    );
};