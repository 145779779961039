// App.js
import React from "react";
import { GanttContextProvider } from "./contexts/GanttContext";
import { Gantt } from "./components/gantt/Gantt";
import { Header } from "./components/header/Header";
import { Footer } from "./components/footer/Footer";

export const App = () => {
  return <GanttContextProvider>
    <Header />
    <Gantt />
    <Footer />
  </GanttContextProvider>;
}