import React, {
  useContext,
  useState,
} from 'react';
import { readGantt } from '../helpers/GanttHelper';
const GanttContext = React.createContext();

export const GanttContextProvider = ({
  children,
}) => {
  const { m, t } = readGantt();
  const [tasks, setTasks] = useState(t);
  const [mode, setMode] = useState(m);
  const [clickedTask, setClickedTask] = useState();

  const context = {
    tasks, setTasks, mode, setMode, clickedTask, setClickedTask
  };

  return (
    <GanttContext.Provider value={context}>
      {children}
    </GanttContext.Provider>
  );
};

export const useGanttContext = () => {
  const value = useContext(GanttContext);

  if (!value) {
    console.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return {};
  }

  return value;
};