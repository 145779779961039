export const importSchema = {
  json: {
    label: "Текущая схема",
    type: 'textarea',
    samples: [],
  }
}

export const schema = {
  id: {
    label: 'ID',
    show: true,
    samples: [],
  },
  name: {
    label: 'Название',
    show: true,
    type: "textarea",
    samples: [],
  },
  start: {
    label: 'Начало',
    show: true,
    type: 'date',
    samples: [],
  },
  end: {
    label: 'Окончание',
    show: true,
    type: 'date',
    samples: [],
  },
  progress: {
    label: 'Прогресс',
    show: true,
    type: 'range',
    samples: [],
  },
  custom_class: {
    label: 'Цвет',
    show: true,
    type: 'ganttclass',
    samples: ["bar-zero-progress", "bar-in-test", "bar-red", "bar-bad-done", "bar-done", "bar-empty"],
  },
  dependencies: {
    label: 'Зависимости',
    show: true,
    type: 'textarea',
    samples: [],
  },
  link: {
    label: "Ссылка на задачу",
    show: true,
    samples: []
  }
};

export const fieldTypes = ['textarea', 'input', 'range', 'date', 'ganttclass'];